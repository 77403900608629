<template>
  <el-dialog
    title="分配教师"
    :visible.sync="Visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close"
    width="30%"
  >
    <div style="height: 300px; width: 100%">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item style="width: 100%" label="教师" prop="name">
          <el-select
            v-model="ruleForm.teachers"
            filterable
            allow-create
            width="200"
            multiple
            default-first-option
            placeholder="请选择"
          >
            <el-option
              style="width: 200px"
              v-for="(item, index) in ArrangeTeachers_List"
              :key="index"
              :label="item.full_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            v-model="ruleForm.teachers"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择教师"
          >
            <el-option
              v-for="(item, index) in ArrangeTeachers_List"
              :key="index"
              :label="item.full_name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <!-- <el-autocomplete
					      class="inline-input"
					      v-model="state1"
					      :fetch-suggestions="querySearch"
					      placeholder="请输入内容"
					      @select="handleSelect"
					    ></el-autocomplete> -->
        </el-form-item>
        <el-form-item label="每次上课" prop="Each">
          <div class="padding-sm">
            <div>
              <span class="margin-right">学生扣除</span>
              <el-input-number
                v-model="ruleForm.student_time"
                controls-position="right"
                :min="1"
                style="width: 100px"
              ></el-input-number>
              <span class="margin-left-xs">课时</span>
            </div>
            <div class="margin-top-xs">
              <span class="margin-right">教师获得</span>
              <el-input-number
                v-model="ruleForm.teacher_time"
                controls-position="right"
                :min="1"
                style="width: 100px"
              ></el-input-number>
              <span class="margin-left-xs">课时</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="flex justify-content-flexEnd">
        <el-button type="primary" @click="add_teachers">确定</el-button>
        <el-button class="myClient-left-30" @click="close">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    Visible: {
      type: Boolean,
      default: false,
    },
    initdataList: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      datamodel: {},
      state1: [],
      ArrangeTeachers_List: [],
      restaurants: [],
      groupNmae: "",
      ruleForm: {
        order_curriculum_id: 0,
        teacher_time: 0,
        student_time: 0,
        teachers: [],
      },
      rules: {
        teachers: [
          {
            required: true,
            message: "请选择教师",
            trigger: "change",
          },
          {
            max: 15,
            message: "最多15个字",
            trigger: "blur",
          },
        ],
        Each: [
          {
            required: true,
            message: "请输入课时",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
      this.datamodel = this.initdataList;
      this.ruleForm.order_curriculum_id = this.datamodel.order_curriculum_id; 
    },
  },
  created() {
    this.get_student();
    // this.dialogVisible = this.Visible;
    // this.datamodel = this.initdataList;
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  methods: {
    add_teachers() {
      this.API.OneToOneSetTeacher(this.ruleForm).then((res) => {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.ruleForm = {
          order_curriculum_id: 0,
          teacher_time: 0,
          student_time: 0,
          teachers: [],
        };
        this.$emit("closepop");
      });
    },
    get_student() {
      //查询教师
      var model_teachers = {
        // AccountType: [4],
        post_tag:['教师'],
        KeyWord: "",
        PageSize: 20,
        PageIndex: 1,
      };
      this.API.accountQuery(model_teachers).then((res) => {
        this.ArrangeTeachers_List = res.data.rows;
      });
      //查询助教
    },
    close() {
      this.$emit("closepop");
      this.ruleForm = {
        order_curriculum_id: 0,
        teacher_time: 0,
        student_time: 0,
        teachers: [],
      };
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [
        {
          value: "三全鲜食（北新泾店）",
          address: "长宁区新渔路144号",
        },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        {
          value: "泷千家(天山西路店)",
          address: "天山西路438号",
        },
      ];
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {},
  },
};
</script>

<style  scoped>
</style>
