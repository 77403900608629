<template>
  <div class="total">
    <div class="configure bg-z">
     
          <div class="work-title bg-white   padding">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div class="work-title-content flex aligin-items-center">
                <!-- {{ Classroom_QueryData.shoolid }} -->
                <el-popover width="200" trigger="hover">
                  <el-select
                    v-model="Classroom_QueryData.shoolid"
                    @change="Choose_Campus_change"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      :label="item.name"
                      v-for="(item, index) of school_list"
                      :key="index"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <div slot="reference" class="cursor">
                    <span>校区</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <div v-if="!Classroom_QueryData.shoolid" slot="reference">
                  <i class="el-icon-link text-grey" />
                  <span class="text-grey">教室</span>
                  <i class="el-icon-caret-bottom" />
                </div>
                <el-popover v-else width="350" trigger="click">
                  <el-input v-model="classInput" placeholder="" size="mini" />
                  <el-checkbox-group
                    @change="classroom_change"
                    v-model="classroom_name"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.name"
                      v-for="(item, index) of classroom_list"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <i class="el-icon-link text-grey" />
                    <span>教室</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input v-model="classInput" placeholder="" size="mini" />
                  <el-checkbox-group
                    @change="courseClass_change"
                    v-model="courseClass_name"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.title"
                      v-for="(item, index) of courseClassList"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>课程类别</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <div
                  v-if="!listQueryStudent.curriculum_id.length"
                  slot="reference"
                >
                  <i class="el-icon-link text-grey" />
                  <span class="text-grey">课程</span>
                  <i class="el-icon-caret-bottom" />
                </div>
                <el-popover v-else width="350" trigger="click">
                  <el-input v-model="classInput" placeholder="" size="mini" />
                  <el-checkbox-group
                    @change="Curriculum_change"
                    v-model="Curriculum_name"
                  >
                    <el-checkbox
                      class="padding-xs"
                      :label="item.curriculum_name"
                      v-for="(item, index) of CurriculumList"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <i class="el-icon-link text-grey" />
                    <span>课程</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-radio-group
                    @change="charging_change"
                    v-model="charging_name"
                  >
                    <el-radio
                      v-for="(item, index) in charging_list"
                      :key="index"
                      class="padding-xs"
                      :label="item.Name"
                    />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>收费模式</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="250" trigger="click">
                  <el-date-picker
                    ref="datePicker"
                    v-model="date"
                    type="daterange"
                    @change="date_change"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                  <div slot="reference" class="cursor">
                    <span>开课日期</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>

                <el-popover width="150" trigger="click">
                  <el-checkbox-group v-model="listQueryStudent.weeks">
                    <el-checkbox
                      class="padding-xs"
                      :label="item.label"
                      v-for="(item, index) of weekList"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference" class="cursor">
                    <span>上课星期</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <div v-if="!listQueryStudent.weeks.length" slot="reference">
                  <i class="el-icon-link text-grey" />
                  <span class="text-grey">上课时段</span>
                  <i class="el-icon-caret-bottom" />
                </div>

                <el-popover v-else width="80" trigger="click">
                  <el-time-picker
                    is-range
                    @change="time_slot_change"
                    format="HH mm"
                    value-format="HH:mm"
                    v-model="time_slot"
                    range-separator="-"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                  >
                  </el-time-picker>
                  <!-- <el-checkbox-group
                    @change="time_slot_change"
                    v-model="time_slot"
                  >
                    <el-checkbox class="padding-xs" label="上午" />
                    <el-checkbox class="padding-xs" label="下午" />
                  </el-checkbox-group> -->
                  <div slot="reference" class="cursor">
                    <i class="el-icon-link text-grey" />
                    <span>上课时段</span>
                    <i class="el-icon-caret-bottom" />
                  </div>
                </el-popover>
                <el-popover width="150" trigger="click">
                  <el-radio-group
                    @change="curr_status_change"
                    v-model="curr_status"
                  >
                    <el-radio class="padding-xs" label="全部" />
                    <el-radio class="padding-xs" label="开课中" />
                    <el-radio class="padding-xs" label="未开课" />
                  </el-radio-group>
                  <div slot="reference" class="cursor">
                    <span>开课状态</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>

              <div class="work-title-search" style="width: 30%">
                <el-input
                  placeholder="姓名，手机号，微信号"
                  v-model="KeyWord"
                  class="input-with-select"
                  size="mini"
                  style="width: 66%"
                >
                  <el-button
                    slot="append"
                    @click="select_Student"
                    icon="el-icon-search"
                  />
                </el-input>
                <span
                  class="text-blue margin-left-sm cursor"
                  @click="del_selsect"
                  >清空筛选</span
                >
              </div>
            </div>

            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div
                  v-if="Choose_Campus_name"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>校区：</div>
                  <div>
                    <!-- v-for="(btn, btnIndex) in Choose_Campus"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }" -->
                    <el-button size="mini">
                      {{ Choose_Campus_name }}
                      <i
                        class="el-icon-close"
                        @click="
                          del_Choose_Campus('btnIndex', 'Choose_Campus_name')
                        "
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="classroom_name.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>教室：</div>
                  <div>
                    <el-button
                      size="mini"
                      v-for="(btn, btnIndex) in classroom_name"
                      :key="btnIndex"
                      :class="{ 'margin-left': btnIndex > 0 }"
                    >
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus(btnIndex, 'classroom_name')"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="courseClass_name.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>课程类别：</div>
                  <div>
                    <el-button
                      size="mini"
                      v-for="(btn, btnIndex) in courseClass_name"
                      :key="btnIndex"
                      :class="{ 'margin-left': btnIndex > 0 }"
                    >
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus(btnIndex, 'courseClass_name')"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="Curriculum_name.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>课程：</div>
                  <div>
                    <el-button
                      size="mini"
                      v-for="(btn, btnIndex) in Curriculum_name"
                      :key="btnIndex"
                      :class="{ 'margin-left': btnIndex > 0 }"
                    >
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus(btnIndex, 'Curriculum_name')"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="charging_name"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>收费模式：</div>
                  <div>
                    <el-button size="mini">
                      {{ charging_name }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus('btnIndex', 'charging_name')"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent.open_date"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>开课日期：</div>
                  <div>
                    <el-button size="mini">
                      {{ this.listQueryStudent.open_date }}
                      <i
                        class="el-icon-close"
                        @click="
                          del_Choose_Campus('btnIndex', 'registration_time')
                        "
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent.weeks.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>上课星期：</div>
                  <div>
                    <el-button
                      size="mini"
                      v-for="(btn, btnIndex) in listQueryStudent.weeks"
                      :key="btnIndex"
                      :class="{ 'margin-left': btnIndex > 0 }"
                    >
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus(btnIndex, 'weeks')"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="listQueryStudent.time_slot"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>上课时段：</div>
                  <div>
                    <el-button size="mini">
                      {{ this.listQueryStudent.time_slot }}
                      <i
                        class="el-icon-close"
                        @click="del_Choose_Campus('btnIndex', 'time_slot')"
                      />
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 列表 -->
          <div class="work-list margin-top bg-white">
            <!-- <div class="padding flex justify-content-between">
              <div class="flex">
                <el-button size="mini">导出当前结果</el-button>
                <el-button size="mini">发送通知</el-button>
                <el-button size="mini">批量编辑</el-button>
                <el-button size="mini">批量转课</el-button>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="建议您先筛选出报读同校区间课程的学员"
                  placement="top"
                >
                  <i
                    class="el-icon-question text-blue margin-top-xs margin-left-sm"
                  />
                </el-tooltip>
              </div>
              <div class="flex">
               
                <el-popover width="300" trigger="click">
                  <el-checkbox-group v-model="schoolList">
                    <el-checkbox
                      class="padding-xs"
                      :label="item.label"
                      v-for="(item, index) of customizeList"
                      :key="index"
                    />
                  </el-checkbox-group>
                  <div slot="reference">
                    <el-button type="primary" size="mini"
                      >自定义显示列</el-button
                    >
                  </div>
                </el-popover>
              </div> -->
            </div>
            <div class="padding border-tb">
              当前结果：共计<label>{{ rosterSize }}</label
              >条记录 
            <!--  |  <label>33</label>名在读学员 -->
            </div>
            <!-- @SelectionChange="selectAllChange" -->
            <as-list
              ref="studentListAsList"
              :ajaxMethods="{ list: 'GetListForOneToOne' }"
              :listQueryProp="listQueryStudent"
              @requestChange="requestChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <!-- <el-table-column
                align="center"
                prop="orderId"
                label="星标"
                width="70px"
              >
                <i class="el-icon-star-off"></i>
                <i class="el-icon-star-off"></i>
                <i class="el-icon-star-off"></i>
                <i class="el-icon-star-off"></i>
                <i class="el-icon-star-off"></i>
              </el-table-column> -->

              <el-table-column align="center" sortable label="学员名字">
                <template slot-scope="scope">
                  <router-link
                    replace
                    :to="{
                      path: '/educationCenter/studentInformation',
                      query: { id: scope.row.id },
                    }"
                  >
                    <span class="cursor text-blue">
                      {{ scope.row.FullName }}</span
                    >
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="relationship" label="关系">
                <template slot-scope="scope">
                  <span>{{ scope.row.relation_name }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="phone" label="联系方式">
                <template slot-scope="scope" slot="header">
                  <span>联系方式</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="联系电话可通过学员应用下的权限点进行全显/隐藏控制"
                    placement="top"
                  >
                    <!-- <i class="el-icon-question text-blue" /> -->
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="student_status"
                label="学员状态"
              ></el-table-column>
              <el-table-column align="center" prop="text5" label="课程" />
              <!-- <el-table-column align="center" prop="bind" label="家校中心关注">
                <i class="iconfont icon-home"></i>
              </el-table-column> -->
              <el-table-column align="center" prop="text6" label="开课日期" />
              <el-table-column align="center" prop="text6" label="上课时间" />
              <el-table-column
                align="center"
                prop="school_name "
                label="校区"
              />
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-dropdown trigger="hover">
                    <label>
                      操作<i
                        class="el-icon-arrow-down el-icon-caret-bottom"
                      ></i>
                    </label>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        ><span class="cursor" @click="editHandle(1, scope.row)"
                          >分配教师</span
                        ></el-dropdown-item
                      >
                      <el-dropdown-item>排课</el-dropdown-item>
                      <el-dropdown-item>结课</el-dropdown-item>
                      <el-dropdown-item>排课记上课</el-dropdown-item>
                      <el-dropdown-item>未排课记上课</el-dropdown-item>
                      <el-dropdown-item>课程表</el-dropdown-item>
                      <el-dropdown-item>记上课</el-dropdown-item>
                      <el-dropdown-item>编辑</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </as-list>
          </div>
        
      <assignTeachers
        :initdataList="datamodel"
        :Visible="editVisible"
        @closepop="closeEditPop"
      ></assignTeachers>
    </div>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import { withWithout, weekList } from "@/config/index";
import assignTeachers from "../components/assignTeachers.vue";
import Pending from "./Pending";
import changeDateVue from "../components/changeDate.vue";
export default {
  components: {
    AsList,
    assignTeachers,
    Pending,
  },
  data() {
    return {
      curr_status: "", //开课状态显示
      time_slot: ["", ""], //上课时段
      charging_name: "",
      charging_list: [],
      KeyWord: "",
      Curriculum_name: [],
      CurriculumList: [], //课程列表
      courseClass_name: [], //选择课程类别
      courseClassList: [], //课程类别
      //获取数据字典
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 10,
      },
      rosterSize: 0, //总条数
      datamodel: {},
      classroom_name: [], //教室名称
      classroom_list: [], //教室列表
      Choose_Campus: "",
      Classroom_QueryData: {
        shoolid: "",
        KeyWord: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
      },
      school_list: [], //校区
      Choose_Campus_name: "", //选择校区

      listQueryStudent: {
        room_id: [],
        school_id: [],
        curriculum_id: [], //课程id
        charging_mode: [], //收费模式
        open_date: "", //开课日期
        curr_status: [0], //开课状态
        weeks: [],
        time_slot: "",
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      withWithout,
      weekList,
      isShow: false,
      showDate: true,
      show4: false,
      show5: false,
      date: "",
      value1: "", //开班时间
      value2: "", //结班时间
      activeName: "two",
      stuInput: "", // 学生姓名查询
      classInput: "",
      Star: "",
      balance: "",
      orderTagList: [], // 订单标签
      schoolList: [], // 经办校区
      orderSatus: ["待支付", "已完成"], // 订单状态
      customizeList: [
        {
          label: "关系",
          value: "1",
        },
        {
          label: "教师",
          value: "2",
        },
        {
          label: "助教",
          value: "3",
        },
        {
          label: "教室",
          value: "4",
        },
        {
          label: "是否升期",
          value: "5",
        },
        {
          label: "家校中心关注",
          value: "6",
        },
        {
          label: "收费标准",
          value: "7",
        },
        {
          label: "开课日期",
          value: "8",
        },
        {
          label: "上课时间",
          value: "9",
        },
        {
          label: "校区",
          value: "10",
        },
        {
          label: "已用课时",
          value: "11",
        },
        {
          label: "剩余课时",
          value: "12",
        },
        {
          label: "已用学费",
          value: "13",
        },
        {
          label: "剩余学费",
          value: "14",
        },
      ],
    };
  },
  created() {
    this.get_NeedInformation();
  },

  methods: {
    //开课状态
    curr_status_change() {
      if (this.curr_status == "开课中") {
        this.listQueryStudent.curr_status = [1];
      } else if (this.curr_status == "未开课") {
        this.listQueryStudent.curr_status = [2];
      } else {
        this.listQueryStudent.curr_status = [0];
      }
    },
    //上课时段
    time_slot_change() {
      this.listQueryStudent.time_slot =
        this.time_slot[0] + "~" + this.time_slot[1];
    },
    //报读日期
    date_change(e) {
      if (this.date.length)
        this.listQueryStudent.open_date = this.date[0] + "~" + this.date[1];
    },
    del_selsect() {
      this.listQueryStudent = {
        room_id: [],
        school_id: [],
        curriculum_id: [], //课程id
        charging_mode: [], //收费模式
        open_date: "", //开课日期
        curr_status: [0],
        weeks: [],
        time_slot: "",
        KeyWord: "",
        TotalCount: "",
        PageIndex: 1,
        PageSize: 10,
      };
      this.time_slot = ["", ""]; //上课时段
      this.charging_name = "";
      this.charging_list = [];
      this.KeyWord = "";
      this.Curriculum_name = [];
      this.courseClass_name = []; //选择课程类别
      this.curr_status = ""; //开课状态显示
      this.Choose_Campus_name = ""; //选择校区
    },
    select_Student() {
      this.listQueryStudent.KeyWord = this.KeyWord;
    },
    charging_change(e) {
      var charging_name = this.charging_list.filter((rs) => {
        return this.charging_name.indexOf(rs.Name) != -1;
      });
      this.listQueryStudent.charging_mode = charging_name.map((rs) => {
        return rs.Key;
      });
    },
    requestChange(res) {
      this.rosterSize = res.total;
      // this.arrearMoney = res.outer_data.arrearMoney;
    },
    //选择课程
    Curriculum_change() {
      var Curriculum_name = this.Curriculum_name.filter((rs) => {
        return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
      });
      this.listQueryStudent.curriculum_id = Curriculum_name.map((rs) => {
        return rs.id;
      });
    },
    //选择课程类别
    courseClass_change() {
      var courseClass_list = this.courseClassList.filter((rs) => {
        return this.courseClass_name.indexOf(rs.title) != -1;
      });
      this.listQueryStudent.curriculum_id = courseClass_list.map((rs) => {
        return rs.id;
      });
      this.getCurriculumList(); //课程类别下的课程
    },
    //选择教室classroom_change
    classroom_change() {
      var classroom_list = this.classroom_list.filter((rs) => {
        return this.classroom_name.indexOf(rs.name) != -1;
      });
      this.listQueryStudent.Classroomid = classroom_list.map((rs) => {
        return rs.id;
      });
    },
    //删除校区搜索
    del_Choose_Campus(index, del) {
      if (del == "Choose_Campus_name") {
        //清除校区
        this.Choose_Campus_name = "";
        this.Classroom_QueryData.shoolid = "";
        this.classroom_name = [];
        this.listQueryStudent.Classroomid = [];
      } else if (del == "classroom_name") {
        //清除教室
        this.classroom_name.splice(index, 1);
        var classroom_list = this.classroom_list.filter((rs) => {
          return this.classroom_name.indexOf(rs.name) != -1;
        });
        this.listQueryStudent.Classroomid = classroom_list.map((rs) => {
          return rs.id;
        });
      } else if (del == "courseClass_name") {
        //清除课程类别
        this.courseClass_name.splice(index, 1);
        var courseClassList = this.courseClassList.filter((rs) => {
          return this.courseClass_name.indexOf(rs.title) != -1;
        });
        this.listQueryStudent.curriculum_id = courseClassList.map((rs) => {
          return rs.id;
        });
        this.getCurriculumList(); //课程类别下的课程
      } else if (del == "Curriculum_name") {
        //清除课程
        this.Curriculum_name.splice(index, 1);
        var Curriculum_name = this.Curriculum_name.filter((rs) => {
          return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
        });
        this.listQueryStudent.curriculum_id = Curriculum_name.map((rs) => {
          return rs.id;
        });
      } else if (del == "charging_name") {
        //清除收费模式
        this.charging_name = "";
        this.listQueryStudent.charging_mode = null;
      } else if (del == "registration_time") {
        //报读日期
        this.listQueryStudent.open_date = "";
        this.date = [];
      } else if (del == "weeks") {
        //上课星期
        this.listQueryStudent.weeks.splice(index, 1);
        if (!this.listQueryStudent.weeks.length) {
          this.listQueryStudent.time_slot = "";
          this.time_slot = ["", ""];
        }
      } else if (del == "time_slot") {
        //上课时段
        this.listQueryStudent.time_slot = "";
        this.time_slot = ["", ""];
      }
    },
    Choose_Campus_change() {
      for (let index = 0; index < this.school_list.length; index++) {
        if (this.school_list[index].id == this.Classroom_QueryData.shoolid) {
          this.Choose_Campus_name = this.school_list[index].name;
        }
      }
      this.listQueryStudent.school_id = [];
      this.listQueryStudent.school_id.push(this.Classroom_QueryData.shoolid);

      this.get_getClassroomList();
    },
    get_NeedInformation() {
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      };
      this.API.OrganizationList(model).then((res) => {
        this.school_list = res.data.rows;
      });
      // 获取课程类别
      this.listQuery.module = "course_class";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.courseClassList = res.data.rows;
        this.courseClassList = this.courseClassList.filter((x) => {
          return x.parent_id != 0;
        });
        this.$forceUpdate();
      });
      //获取收费模式枚举
      this.API.SystemGetSettings("ChargingMode", false).then((res) => {
        this.charging_list = res;
      });
    },
    get_getClassroomList() {
      var model = {
        shoolid: this.Classroom_QueryData.shoolid,
        KeyWord: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
      };
      this.API.getClassroomList(model).then((res) => {
        this.classroom_list = res.data.rows || [];
      });
    },
    //获取课程列表
    getCurriculumList() {
      var model = {
        // tmode: 0,
        // open_school: this.Classroom_QueryData.shoolid,
        curriculum_type: this.listQueryStudent.curriculum_id,
        KeyWord: "",
        // TotalCount: 0,
        PageIndex: 1,
        PageSize: 15,
      };
      this.API.getCurriculumList(model).then((res) => {
        this.CurriculumList = res.data.rows || [];
        // this.queryResult = Object.assign({}, this.queryResult, res.data);
      });
    },
    openDate() {
      this.$refs.datePicker.pickerVisible = true;
    },
    delOderStatus(index) {
      this.orderSatus.splice(index, index + 1);
    },
    goNav(url) {
      this.$router.push(url);
    },
    editHandle(type, row) {
      this.datamodel = row;
      this.editVisible = true;
    },
  },
};
</script>
<style scoped>
.icon {
  width: 50px;
  height: 50px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.el-input__inner {
  width: 220px !important;
}

.el-button--mini {
  border-radius: 4px !important;
  padding: 6px 15px !important;
}
</style>
